import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navigate } from "@reach/router"
import logoBi from "../../images/logo_bi_grey.svg"
import { useStateIfMounted } from "use-state-if-mounted"
import { Row, Col, Container, Button } from "react-bootstrap"
import { useLocation } from "@reach/router"
import { CustomButton } from "../Reusable/button"
import NavigationBar from "../HeaderComponents/NavigationBar"
import styled from "styled-components"
import BusinessHeaderImg from "../../images/business-new-header.png"
import BusinessHeaderImg2 from "../../images/business-new-header-2.png"

const Legal = ({ isEnglish }) => {
  const location = useLocation()
  const lang = location.pathname.includes("/en")
  return (
    <LegalBusiness
      bottom={isEnglish ? "-5vw" : "-1vw"}
      right={isEnglish ? "1vw" : "2vw"}
    >
      <img src={logoBi} alt="logo BI" className="mb-2" />
      <BusinessP style={{ fontSize: "11px", color: "#828282" }}>
        {lang
          ? "We're a licensed remittance"
          : "Memiliki Izin dan diawasi oleh"}{" "}
        <br />
        {lang ? "company under the regulation of" : ""} {lang ? <br /> : null}
        {lang
          ? "Bank of Indonesia, license number"
          : "Bank Indonesia Nomor lisensi"}{" "}
        <br />
        <a
          href="https://www.bi.go.id/id/qrcode/validate.aspx?id=0001.001/0024&tipe=PTD"
          target="_blank"
          style={{ color: "#48a9b5" }}
        >
          13/77/DASP/30.
        </a>
      </BusinessP>
    </LegalBusiness>
  )
}

export const BusinessHeader = ({ props, propsData, propsLink, isEnglish }) => {
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = status => {
    setStatusNav(status)
  }

  const { Title1, Title2, Desc1, Button1, Button2 } = propsData

  return (
    <MainDivHeader style={statusNav ? { paddingTop: "80px" } : null}>
      <NavigationBar
        props={props}
        propsLink={propsLink}
        parentCallback={statusNavbar}
        isWhite
        isBusiness
        noRegional
      />
      <BusinessContainer>
        <Row>
          <Col lg={5}>
            <BusinessDiv>
              <BusinessTitle>
                {Title1}
                <br />
                {Title2}
                <YellowSpan />
                <Border />
              </BusinessTitle>
              <BusinessDesc>{Desc1}</BusinessDesc>
            </BusinessDiv>
            <BusinessDiv mtop="75px">
              <Link to="#form">
                <BusinessButtonYellow>{Button1}</BusinessButtonYellow>
              </Link>
              <br />
              <a
                href="https://calendly.com/transfez-b2b-demo/transfez-b2b-demo"
                target="_blank"
              >
                <BusinessButtonTransparent>{Button2}</BusinessButtonTransparent>
              </a>
            </BusinessDiv>
          </Col>
          <DivImgAndLegal lg={7}>
            <HeaderImg src={BusinessHeaderImg} />
            <Legal isEnglish={isEnglish} />
          </DivImgAndLegal>
        </Row>
      </BusinessContainer>
    </MainDivHeader>
  )
}

const MainDivHeader = styled.div`
  min-height: 700px;
  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
  @media (min-width: 768px) {
    margin-bottom: 130px;
  }
`

const DivImgAndLegal = styled(Col)`
  position: relative;
  width: 100%;
  @media (max-width: 992px) {
    display: none;
  }
`

const HeaderImg = styled.img`
  /* min-width: 820px; */
  /* margin-top: -450px;
  margin-left: -60px; */

  position: absolute;
  min-width: 1400px;
  top: -480px;
  right: -200px;
  z-index: -2;
  @media (max-width: 768px) {
    position: relative;
  }
`

const BusinessDiv = styled.div`
  text-align: left;
  margin-top: ${p => p.mtop};
  @media (max-width: 576px) {
    text-align: center;
    padding: 0px 30px;
  }
`

const BusinessContainer = styled(Container)`
  margin-top: 45px;
  margin-bottom: -50px;
  /* padding-left: 100px; */
  @media (max-width: 992px) {
    padding-left: 0px;
  }
`
const BusinessTitle = styled.h2`
  font-family: "GothamBold";
  color: ${props => props.color || "#48a9b5"};
  font-size: 50px;
  @media (max-width: 576px) {
    font-size: 42px;
    text-align: center;
  }
`

const BusinessDesc = styled.p`
  font-family: "GothamBook";
  font-size: 18px;
  color: #828282;
`

const YellowSpan = styled.div`
  display: inline-block;
  background-color: #ffd27c;
  margin-left: 2px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
`

const Border = styled.div`
  width: 50px;
  height: 5px;
  background-color: #ffd27c;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  @media (max-width: 576px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const BusinessButtonYellow = styled(Button)`
  width: 257px;
  height: 50px;
  background-color: #ffd27c;
  color: #166f7b;
  font-family: "GothamMedium";
  font-size: 16;
  outline: none;
  border-color: #ffd27c;
  border-radius: 10px;
  margin-top: 10px;
  -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
  box-shadow: -1px 7px 5px -4px #a0a0a0;
  :hover,
  :active,
  :focus {
    background-color: #166f7b;
    color: #ffd27c;
    outline: none;
    box-shadow: none;
  }
`

const BusinessButtonTransparent = styled(Button)`
  width: 257px;
  height: 50px;
  background-color: white;
  color: #48a9b5;
  font-family: "GothamMedium";
  font-size: 16;
  outline: none;
  border-color: #48a9b5;
  border-radius: 10px;
  margin-top: 10px;
  :hover,
  :active,
  :focus {
    background-color: white;
    border-color: #48a9b5;
    color: #48a9b5;
    outline: none;
    box-shadow: none;
    filter: brightness(95%);
  }
`

const BusinessP = styled.p`
  font-family: "GothamBook";
  color: #6c6365;
  margin-bottom: 0;
  @media (max-width: 576px) {
    text-align: center;
  }
  @media (max-width: 1024px) {
    margin-left: 15px;
  }
`
const LegalBusiness = styled.div`
  font-family: "GothamBook";
  position: absolute;
  bottom: ${p => p.bottom};
  right: ${p => p.right};
  @media (max-width: 1024px) {
    display: flex;
    bottom: 0px;
  }
`
