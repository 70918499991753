import React from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import rocketIcon from "../../images/business-adv-rocket.svg"
import targetIcon from "../../images/business-adv-target.svg"
import eyeIcon from "../../images/business-adv-eye.svg"
import shieldIcon from "../../images/business-adv-shield.svg"

export const Advantages = ({ props }) => {
  const {
    Title,
    Point1,
    Point2,
    Point3,
    Point4,
    Desc1,
    Desc2,
    Desc3,
    Desc4,
  } = props
  return (
    <div>
      <Container>
        <MainTitle>
          {Title}
          <YellowSpan>?</YellowSpan>
        </MainTitle>
      </Container>
      <Container>
        <MainContainer>
          <Row>
            <MainCol lg={6} mbottom="3rem" mtopres="1.5rem">
              <img src={rocketIcon} />
              <TextDiv>
                <TextPoint>{Point1}</TextPoint>
                <TextDesc>{Desc1}</TextDesc>
              </TextDiv>
            </MainCol>
            <MainCol lg={6} mbottom="3rem">
              <img src={targetIcon} />
              <TextDiv>
                <TextPoint>{Point2}</TextPoint>
                <TextDesc>{Desc2}</TextDesc>
              </TextDiv>
            </MainCol>
          </Row>
          <Row>
            <MainCol lg={6}>
              <img src={shieldIcon} />
              <TextDiv>
                <TextPoint>{Point3}</TextPoint>
                <TextDesc>{Desc3}</TextDesc>
              </TextDiv>
            </MainCol>
            <MainCol lg={6}>
              <img src={eyeIcon} />
              <TextDiv>
                <TextPoint>{Point4}</TextPoint>
                <TextDesc>{Desc4}</TextDesc>
              </TextDiv>
            </MainCol>
          </Row>
        </MainContainer>
      </Container>
    </div>
  )
}

const MainContainer = styled(Container)`
  background-color: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 50px 100px;
  @media (max-width: 992px) {
    padding: 25px 50px;
  }
`
const MainTitle = styled.h2`
  color: #49abb8;
  font-family: "GothamBold";
  font-size: 38px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
`
const YellowSpan = styled.span`
  color: #ffd27c;
  font-family: "GothamBold";
`

const MainCol = styled(Col)`
  display: flex;
  margin-bottom: ${p => p.mbottom};
  @media (max-width: 992px) {
    margin-top: ${p => p.mtopres};
    margin-bottom: 3rem;
  }
`

const TextDiv = styled.div`
  margin-left: -30px;
`
const TextPoint = styled.h2`
  color: #166e7b;
  font-size: 22px;
  font-family: "GothamMedium";
`

const TextDesc = styled.p`
  color: #828282;
  font-family: "GothamBook";
  font-size: 14px;
  width: 80%;
  @media (max-width: 992px) {
    width: 100%;
  }
`
