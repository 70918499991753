import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BusinessRightImg from "../../images/business-reason-right.png"
import GreyArrow from "../../images/business-grey-arrow.png"
// import GreyArrow from "../../images/business-grey-arrow.svg"
import CrossborderImg from "../../images/business-crossborder.png"
import LocaldisbImg from "../../images/business-localdisb.png"
import LocalcollectImg from "../../images/business-localcollection.png"

export const Features = ({ props }) => {
  const {
    Title,
    SubTittle1,
    SubTittle2,
    Point1,
    Point2,
    Point3,
    Desc1,
    Desc2,
    Desc3,
  } = props

  const [activeKey, setActiveKey] = useState("crossborder")
  const [activeImg, setActiveImg] = useState(CrossborderImg)

  const HandleImg = img => {
    setActiveKey(img)
  }
  const isCrossborder = activeKey == "crossborder"
  const isDisbursement = activeKey == "disbursement"
  const isCollection = activeKey == "collection"

  useEffect(() => {
    setActiveImg(
      isCrossborder
        ? CrossborderImg
        : isDisbursement
        ? LocaldisbImg
        : LocalcollectImg
    )
  }, [activeKey])

  useEffect(() => {
    const time = 3000
    if (isCrossborder) {
      const interval = setInterval(() => setActiveKey("disbursement"), time)
      return () => {
        clearInterval(interval)
      }
    }
    if (isDisbursement) {
      const interval = setInterval(() => setActiveKey("collection"), time)
      return () => {
        clearInterval(interval)
      }
    }
    if (isCollection) {
      const interval = setInterval(() => setActiveKey("crossborder"), time)
      return () => {
        clearInterval(interval)
      }
    }
  }, [activeKey])

  return (
    <div>
      <Container>
        <MainTitle>
          {Title}
          <YellowSpan />
        </MainTitle>
        <Row>
          <Col md={6}>
            <div>
              <YellowFlag>
                <CardTitle fsize="20px" color="white">
                  {SubTittle1}
                </CardTitle>
              </YellowFlag>
              <BodyFlag mbottom="30px">
                <BodyFlagRow>
                  <DivFlag
                    onClick={() => HandleImg("crossborder")}
                    bcolor={isCrossborder ? "rgb(255, 239, 210, 0.6)" : "#fff"}
                  >
                    <ColFlag md={5}>
                      <CardTitle>{Point1}</CardTitle>
                    </ColFlag>
                    <ColFlag md={7}>
                      <CardDesc>{Desc1}</CardDesc>
                      {isCrossborder && <ProductImg src={GreyArrow} />}
                    </ColFlag>
                  </DivFlag>
                </BodyFlagRow>
                <Hline />
                <BodyFlagRow mbottom="20px">
                  <DivFlag
                    onClick={() => HandleImg("disbursement")}
                    bcolor={isDisbursement ? "rgb(255, 239, 210, 0.6)" : "#fff"}
                  >
                    <ColFlag md={5}>
                      <CardTitle>{Point2}</CardTitle>
                    </ColFlag>
                    <ColFlag md={7}>
                      <CardDesc>{Desc2}</CardDesc>
                      {isDisbursement && <ProductImg src={GreyArrow} />}
                    </ColFlag>
                  </DivFlag>
                </BodyFlagRow>
              </BodyFlag>

              <YellowFlag>
                <CardTitle fsize="20px" color="white">
                  {SubTittle2}
                </CardTitle>
              </YellowFlag>
              <BodyFlag mbottom="30px">
                <BodyFlagRow mbottom="15px">
                  <DivFlag
                    onClick={() => HandleImg("collection")}
                    bcolor={isCollection ? "rgb(255, 239, 210, 0.6)" : "#fff"}
                  >
                    <ColFlag md={5}>
                      <CardTitle>{Point3}</CardTitle>
                    </ColFlag>
                    <ColFlag md={7}>
                      <CardDesc>{Desc3}</CardDesc>
                      {isCollection && <ProductImg src={GreyArrow} />}
                    </ColFlag>
                  </DivFlag>
                </BodyFlagRow>
              </BodyFlag>
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <BigImg src={activeImg} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const MainTitle = styled.h2`
  color: #49abb8;
  font-family: "GothamBold";
  font-size: 38px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
`

const YellowSpan = styled.div`
  display: inline-block;
  background-color: #ffd27c;
  height: 8px;
  width: 8px;
  border-radius: 50%;
`

const YellowFlag = styled.div`
  background-color: #ffd07c;
  border-top-left-radius: 15px;
  padding: 5px 20px;
  width: 40%;
  margin-bottom: 0;
  @media (max-width: 576px) {
    width: 50%;
  }
`

const BodyFlag = styled.div`
  background-color: #fff;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 25px;
  padding: 25px 30px;
  margin-bottom: ${p => p.mbottom};
  position: relative;
`

const DivFlag = styled(Row)`
  background-color: ${p => p.bcolor};
  padding: 5px;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 576px) {
    background-color: white;
  }
`

const ColFlag = styled(Col)`
  padding-right: 0px;
  padding-left: 0px;
  min-width: ${p => p.minwidth};
`

const Hline = styled.hr`
  height: 1px;
  margin: 30px 0px;
`

const BodyFlagRow = styled(Row)`
  flex-wrap: nowrap;
  margin-bottom: ${p => p.mbottom};
`

const CardTitle = styled.h5`
  font-size: ${p => p.fsize || "22px"};
  font-family: "GothamMedium";
  color: ${p => p.color || "#166e7b"};
  margin-bottom: 0;
`

const CardDesc = styled.p`
  font-family: "GothamBook";
  font-size: 14px;
  color: #828282;
  margin-bottom: 0;
  @media (max-width: 992px) {
    font-size: 12px;
  }
`

const BigImg = styled.img`
  min-width: 120%;
  margin-left: 100px;
  @media (max-width: 768px) {
    display: none;
  }
`

const ProductImg = styled.img`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: -55px;
  @media (max-width: 768px) {
    display: none;
  }
`
