import React from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import ecommerceIcon from "../../images/business-ecommerce.svg"
import educationIcon from "../../images/business-education.svg"
import financialIcon from "../../images/business-financial.svg"
import logisticIcon from "../../images/business-logistic.svg"
import ngoIcon from "../../images/business-ngo.svg"
import supplierIcon from "../../images/business-supplier.svg"
import techIcon from "../../images/business-tech.svg"
import travelIcon from "../../images/business-travel.svg"

export const BusinessNeeds = ({ props }) => {
  const {
    Title,
    Point1,
    Point2,
    Point3,
    Point4,
    Point5,
    Point6,
    Point7,
    Point8,
    Desc1,
    Desc2,
    Desc3,
    Desc4,
    Desc5,
    Desc6,
    Desc7,
    Desc8,
  } = props

  const CardData = [
    {
      title: Point1,
      desc: Desc1,
      icon: supplierIcon,
    },
    {
      title: Point2,
      desc: Desc2,
      icon: techIcon,
    },
    {
      title: Point3,
      desc: Desc3,
      icon: ngoIcon,
    },
    {
      title: Point4,
      desc: Desc4,
      icon: logisticIcon,
    },
    {
      title: Point5,
      desc: Desc5,
      icon: travelIcon,
    },
    {
      title: Point6,
      desc: Desc6,
      icon: financialIcon,
    },
    {
      title: Point7,
      desc: Desc7,
      icon: ecommerceIcon,
    },
    {
      title: Point8,
      desc: Desc8,
      icon: educationIcon,
    },
  ]
  return (
    <MainDiv>
      <MainContainer>
        <MainTitle>
          {Title}
          <YellowSpan />
        </MainTitle>

        <MainRow>
          {CardData.map(({ title, desc, icon }, index) => {
            const isSpace = title.includes(" ") && title.split(" ")
            return (
              <Col key={index} className="col-6 col-lg-3 px-0">
                <Card>
                  <DivCardTitle>
                    {isSpace ? (
                      <>
                        <CardTitle>{isSpace[0]}</CardTitle>
                        <CardTitle>{isSpace[1]}</CardTitle>
                      </>
                    ) : (
                      <CardTitle>{title}</CardTitle>
                    )}
                  </DivCardTitle>
                  <CardDesc>{desc}</CardDesc>
                  <CardIcon src={icon} />
                </Card>
              </Col>
            )
          })}
        </MainRow>
      </MainContainer>
    </MainDiv>
  )
}

const MainDiv = styled.div`
background-color: white;
  box-shadow: 0px -15px 50px rgba(0, 0, 0, 0.04);
  margin-top: 100px;
`

const MainContainer = styled(Container)`
  padding: 70px 15px;
  background-color: white;
`

const YellowSpan = styled.div`
  display: inline-block;
  background-color: #ffd27c;
  height: 8px;
  width: 8px;
  border-radius: 50%;
`

const MainTitle = styled.h2`
  color: #49abb8;
  font-family: "GothamBold";
  font-size: 38px;
  text-align: center;
  margin-bottom: 50px;
`

const MainRow = styled(Row)`
  padding-bottom: 50px;
`

const Card = styled.div`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
  background-color: white;
  padding: 20px;
  height: 250px;
  margin: 10px 10px;
`

const DivCardTitle = styled.div`
  min-height: ${p => p.minHeight || "70px"};
`

const CardTitle = styled.h5`
  font-size: 22px;
  font-family: "GothamBold";
  color: #166e7b;
  margin-bottom: 0;
`

const CardDesc = styled.p`
  font-family: "GothamBook";
  font-size: 14px;
  color: #828282;
  @media (max-width: 992px) {
    font-size: 12px;
  }
`
const CardIcon = styled.img`
  position: absolute;
  bottom: 25px;
  right: 25px;
`
