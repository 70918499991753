import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navigate } from "@reach/router"
import logoBi from "../../images/logo_bi_grey.svg"
import { useStateIfMounted } from "use-state-if-mounted"
import { Row, Col } from "react-bootstrap"
import { useLocation } from "@reach/router"
import {
  BusinessHeader,
  BusinessDiv,
  BusinessContainer,
  BusinessImgDiv,
  BusinessTitle,
  BusinessDot,
  AffiliateBorder,
  BusinessP,
  BusinessButtonYellow,
  BusinessButtonTransparent,
  LegalBusiness,
  BusinessBr,
} from "../HeaderComponents/HeaderStyle"
import { CustomButton } from "../Reusable/button"
import NavigationBar from "../HeaderComponents/NavigationBar"
import BusinessHeaderImg from "../../images/business-header.png"
import { IdComponent } from "../Reusable/id-components"

const Legal = () => {
  const location = useLocation()
  const lang = location.pathname.includes("/en")
  return (
    <>
      <LegalBusiness className="d-flex justify-content-end">
        <BusinessP style={{ fontSize: "11px", color: "#828282" }}>
          {lang
            ? "We're a licensed remittance"
            : "Memiliki Izin dan diawasi oleh"}{" "}
          <br />
          {lang ? "company under the regulation of" : ""} {lang ? <br /> : null}
          {lang
            ? "Bank of Indonesia, license number"
            : "Bank Indonesia Nomor lisensi"}{" "}
          <br />
          <a
            href="https://www.bi.go.id/id/qrcode/validate.aspx?id=0001.001/0024&tipe=PTD"
            target="_blank"
            style={{ color: "#48a9b5" }}
          >
            13/77/DASP/30.
          </a>
        </BusinessP>
        <img src={logoBi} alt="logo BI" className="ml-3" />
      </LegalBusiness>
    </>
  )
}

function HeaderBusiness({ props, propsLink, propsData }) {
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = status => {
    setStatusNav(status)
  }

  const data = useStaticQuery(graphql`
    query {
      headerImg: file(relativePath: { eq: "business-header.png" }) {
        childImageSharp {
          fluid(quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const headerImg = data.headerImg.childImageSharp.fluid

  const {
    Title1,
    Title2,
    Title3,
    Button1,
    Button2,
    Desc1,
    Desc2,
    Desc3,
  } = propsData.Header
  return (
    <BusinessHeader style={statusNav ? { paddingTop: "80px" } : null}>
      <NavigationBar
        props={props}
        propsLink={propsLink}
        parentCallback={statusNavbar}
      />
      <IdComponent title="header" />
      <BusinessContainer>
        <Row>
          <Col md={6}>
            <BusinessDiv>
              <BusinessTitle>
                {Title1}
                <br />
                <span
                  style={{
                    color: "#196e97",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {Title2}
                </span>
                <span>
                  {Title3}
                  <BusinessDot />
                </span>
              </BusinessTitle>
              <AffiliateBorder />
            </BusinessDiv>
            <div className="my-4">
              <BusinessP>
                {Desc1} <BusinessBr />
                {Desc2} <br />
                {Desc3}
              </BusinessP>
            </div>
            <BusinessDiv>
              <Link to="#form">
                {/* <BusinessButtonYellow>{Button1}</BusinessButtonYellow> */}
                <CustomButton
                  isYellow
                  isBgGreen
                  style={{ height: "50px", width: "257px" }}
                  fsize="16px"
                >
                  {Button1}
                </CustomButton>
              </Link>
              <br />
              <a
                href="https://calendly.com/transfez-b2b-demo/transfez-b2b-demo"
                target="_blank"
              >
                <BusinessButtonTransparent>{Button2}</BusinessButtonTransparent>
              </a>
            </BusinessDiv>
          </Col>
          <Col md={6}>
            <BusinessImgDiv>
              <Img fluid={headerImg} alt="header" fadeIn />
              <Legal />
            </BusinessImgDiv>
          </Col>
        </Row>
      </BusinessContainer>
    </BusinessHeader>
  )
}

export default HeaderBusiness
