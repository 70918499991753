import React from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"

import BusinessWorld from "../../images/business-world.png"
import BusinessWorldRes from "../../images/business-testimonies-responsive.png"

export const Testimonies = ({ props }) => {
  const { Title1, Title2 } = props
  return (
    <MainDiv>
      <MainContainer>
        <MainTitle>
          {Title1}
          <YellowSpan />
        </MainTitle>
      </MainContainer>
    </MainDiv>
  )
}

const MainDiv = styled.div`
  background-color: #fff !important;
  margin-bottom: 100px;
  min-height: 50vw;
  padding-bottom: 10vw;
  background-image: url(${BusinessWorld});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    background-image: url(${BusinessWorldRes});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 130vw;
    padding-top: 30vw;
  }
  @media (min-width: 1920px){
    min-height: 30vw;
    padding-bottom: 5vw;
  }
`

const MainContainer = styled(Container)`
  /* min-height: 50vw; */
  /* justify-content: center; */
`

const MainTitle = styled.h2`
  color: #49abb8;
  font-family: "GothamBold";
  text-align: center;
  z-index: 2;
  font-size: 2.6vw;
  /* margin-top: -6vw; */
  /* margin-bottom: 0 !important; */
  @media (max-width: 576px) {
    margin-top: 20vw;
    font-size: 5vw;
  }
  @media (min-width: 1920px){
    font-size: 2.2rem;
  }
`

const YellowSpan = styled.div`
  display: inline-block;
  background-color: #ffd27c;
  height: 0.5vw;
  width: 0.5vw;
  border-radius: 50%;
  @media (max-width: 576px) {
    height: 1vw;
    width: 1vw;
  }
`
