import React from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import businessSalesImg from "../../images/business-sales.png"
import { CustomButton } from "../Reusable/button"

export const Contact = ({ props }) => {
  const { Title, Button, Span, Span1, Desc } = props
  return (
    <div
      style={{
        backgroundColor: "#fff",
        boxShadow: "0px -15px 50px rgba(0, 0, 0, 0.05)",
      }}
    >
      <MainContainer>
        <Row>
          <Col md={6} style={{ paddingTop: "50px" }}>
            <MainTitle>
              {Title}
              <YellowSpan />
            </MainTitle>
            <TextDesc>{Desc}</TextDesc>
            <CtaDiv>
              <a href="https://wa.me/6282298653838" target="_blank">
                <CustomButton
                  isYellow
                  isBgGreen
                  style={{ height: "45px", width: "257px", color: "#166F7B" }}
                  fsize="16px"
                >
                  {Button}
                </CustomButton>
              </a>
              <OrTitle>{Span}</OrTitle>
              <FillFormDiv>
                <CardTitle>{Span1}</CardTitle>
              </FillFormDiv>
            </CtaDiv>
          </Col>
          <Col md={6}>
            <TestingComponent
              src={businessSalesImg}
              style={{ minWidth: "135%" }}
            />
          </Col>
        </Row>
      </MainContainer>
    </div>
  )
}

const MainTitle = styled.h2`
  color: #49abb8;
  font-family: "GothamBold";
  font-size: 42px;
  text-align: left;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 38px;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`

const TextDesc = styled.p`
  color: #828282;
  font-family: "GothamBook";
  font-size: 14px;
  @media (max-width: 992px) {
    width: 100%;
    text-align: center;
  }
`

// const YellowSpan = styled.span`
//   color: #ffd27c;
//   font-family: "GothamBold";
// `

const YellowSpan = styled.div`
  display: inline-block;
  background-color: #ffd27c;
  height: 0.5vw;
  width: 0.5vw;
  border-radius: 50%;
  margin-left: 3px;
  @media (max-width: 576px) {
    height: 1vw;
    width: 1vw;
  }
`

const MainContainer = styled(Container)`
  background-color: #fff;
  @media (max-width: 768px) {
    padding-left: 20px;
  }
`

const CtaDiv = styled.div`
  position: absolute;
  text-align: center;
  width: 257px;
  bottom: 0;
  @media (max-width: 992px) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
`
const OrTitle = styled.p`
  color: #828282;
  font-size: 16px;
  font-family: "GothamMedium";
  margin-top: 20px;
  margin-bottom: 20px;
`

const FillFormDiv = styled.div`
  background-color: #f8f8f8;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
`

const CardTitle = styled.h5`
  font-size: 16px;
  font-family: "GothamMedium";
  color: #166e7b;
  margin-bottom: 0;
  padding: 20px 24px;
`

const TestingComponent = styled.img`
  @media (max-width: 767px) {
    display: none;
  }
`
