import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import axios from "axios"
import { useForm } from "react-hook-form"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { IdComponent } from "../Reusable/id-components"

import BusinessCircle from "../../images/business-new-form.png"
import BusinessArrowDown from "../../images/business-form-arrow-down.png"

export const BusinessForm = ({ props, propsLink }) => {
  const {
    Full1,
    Full2,
    Company1,
    Company2,
    Phone1,
    Phone2,
    Email1,
    Email2,
    Send,
    Message1,
    Message2,
    Product1,
    Product2,
    Product3,
    Product4,
    Product5,
    Success,
    Warning,
  } = props
  const [done, setDone] = useState(false)
  const useFormObj = useForm()

  const submit = async val => {
    const newVal = {
      ...val,
      product: val.product.join(", "),
      phone: `${val.codePhone}${val.phone}`,
    }
    delete newVal["codePhone"]
    try {
      const headers = {
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0fQ.HzngeSUFSrGEeCk4lL7zHElSUhljNf_0PSK1uB5UWkg",
      }
      await axios
        .post("https://api.webform.transfez.com/api/v1/businesses/", newVal, {
          headers: headers,
        })
        .then(res => {
          setDone(true)
          navigate(propsLink?.BusinessSuccess)
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const onChangePhone = (value, e) => {
    setValue("codePhone", `(+${e.dialCode}) `)
    setValue("phone", value)
    setDone(false)
  }

  const onChangeText = () => {
    setDone(false)
  }

  const BusinessFormData = [
    {
      name: "first_name",
      label: Full1,
      placeholder: Full2,
      type: "text",
    },
    {
      name: "company",
      label: Company1,
      placeholder: Company2,
      type: "text",
    },
    {
      name: "email",
      label: Email1,
      placeholder: Email2,
      type: "text",
    },
    {
      name: "phone",
      label: Phone1,
      placeholder: Phone2,
      type: "number",
    },
  ]

  const { handleSubmit, watch, register, setValue } = useFormObj

  useEffect(() => {
    if (done) {
      setValue("first_name", "")
      setValue("company", "")
      setValue("email", "")
      setValue("message", "")
      setValue("phone", "+62")
      setValue("product", ["", "", ""])
    }
  }, [done])

  return (
    <div style={{ position: "relative" }}>
      <IdComponent title="form" />
      <MainDiv>
        <MainContainer>
      <ImgArrowDown src={BusinessArrowDown} />
          <Row>
            <Col lg={8}>
              <form onSubmit={handleSubmit(submit)}>
                {BusinessFormData.map(
                  ({ name, label, placeholder, type }, index) => {
                    const isPhone = name == "phone"
                    return (
                      <RowForm key={index}>
                        <ColLabel md={4}>
                          <LabelForm>{label}</LabelForm>
                        </ColLabel>
                        <Col md={8}>
                          {isPhone ? (
                            <PhoneInput
                              country={"id"}
                              value={watch("phone")}
                              onChange={onChangePhone}
                              placeholder={placeholder}
                              countryCodeEditable={false}
                              inputStyle={{
                                fontFamily: "GothamBook",
                                fontSize: "16px",
                                width: "100%",
                                height: "38px",
                              }}
                            />
                          ) : (
                            <FormControl
                              {...register(name, { required: true })}
                              type={type}
                              name={name}
                              placeholder={placeholder}
                              onChange={onChangeText}
                            />
                          )}
                        </Col>
                      </RowForm>
                    )
                  }
                )}
                <RowForm>
                  <ColLabel md={4} display="relative">
                    <LabelForm mtop="10px">{Product1}</LabelForm>
                    <LabelFormGrey>{Product2}</LabelFormGrey>
                  </ColLabel>
                  <Col md={8}>
                    <FormCheck
                      {...register("product", {
                        required: {
                          value: true,
                          message: "error message",
                        },
                      })}
                      type="checkbox"
                      label={Product3}
                      name="product"
                      value={Product3}
                    />
                    <FormCheck
                      {...register("product", { required: "must be filled" })}
                      type="checkbox"
                      label={Product4}
                      name="product"
                      value={Product4}
                    />
                    <FormCheck
                      {...register("product", { required: "must be filled" })}
                      type="checkbox"
                      label={Product5}
                      name="product"
                      value={Product5}
                    />
                  </Col>
                </RowForm>
                <RowForm>
                  <ColLabel md={4}>
                    <LabelForm>{Message1}</LabelForm>
                  </ColLabel>
                  <Col md={8}>
                    <FormControl
                      {...register("message", { required: true })}
                      type="text"
                      as="textarea"
                      name="message"
                      placeholder={Message2}
                      style={{ padding: "10px", borderRadius: "5px" }}
                      onChange={onChangeText}
                    />
                  </Col>
                </RowForm>
                <RowForm>
                  <Col md={4}>
                    {!done && <SmallTextDesc>{Warning}</SmallTextDesc>}
                  </Col>
                  <Col md={8}>
                    <ButtonSend type="submit">{Send}</ButtonSend>
                    <FlexFill>
                      <small>
                        {done && (
                          <>
                            <FontAwesomeIcon icon={faCheckCircle} /> {Success}
                          </>
                        )}
                      </small>
                    </FlexFill>
                  </Col>
                </RowForm>
              </form>
            </Col>
            <Col lg={4} className="d-flex align-items-center">
              <ImgCircle src={BusinessCircle} />
            </Col>
          </Row>
        </MainContainer>
      </MainDiv>
    </div>
  )
}

const MainDiv = styled.div`
  background-color: #f8f8f8;
  padding: 70px 70px 0px 70px;
  box-shadow: -1px -14px 16px -3px rgba(0, 0, 0, 0.04) inset;
  position: relative;
  @media (max-width: 576px) {
    padding: 50px 25px 0px 25px;
  }
`

const MainContainer = styled(Container)`
  background-color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 50px 100px 50px 100px;
  box-shadow: -1px -14px 16px -3px rgba(0, 0, 0, 0.04) inset;
  @media (max-width: 768px) {
    padding: 25px 35px;
  }
`

const LabelForm = styled.h5`
  font-family: "GothamMedium";
  font-size: 16px;
  color: #166f7b;
  margin-top: ${p => p.mtop};
`
const LabelFormGrey = styled.p`
  font-family: "GothamBook";
  font-size: 12px;
  color: #828282;
`

const RowForm = styled(Row)`
  margin-bottom: 1rem;
  @media (max-width: 576px) {
    margin-bottom: 2rem;
  }
`

const ColLabel = styled(Col)`
  display: ${p => p.display || "flex"};
  align-items: center;
`

const FormControl = styled(Form.Control)`
  font-family: "GothamBook";
  width: 100%;
  border: 1px solid #cacaca;
  :hover,
  :active,
  :focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #cacaca;
  }
  ::placeholder {
    color: #cacaca;
  }
`

const FormCheck = styled(Form.Check)`
  font-size: 16px;
  font-family: "GothamBook";
  color: #828282;
  margin-left: 30px;
  padding: 10px;
  border-radius: 5px;
`

const ButtonSend = styled(Button)`
  font-family: "GothamMedium";
  background-color: #ffd27c;
  color: #166f7b;
  border-radius: 7px;
  border: 1px solid #ffd27c !important;
  padding: 0.5rem 2rem !important;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  :hover,
  :focus,
  :active {
    color: #ffd27c;
    background-color: #166f7b !important;
    border: 1px solid #166f7b !important;
    /* filter: brightness(95%); */
    outline: none !important;
    box-shadow: none !important;
  }
`

const ImgCircle = styled.img`
  margin-left: 60px;
  @media (max-width: 992px) {
    display: none;
  }
`

const FlexFill = styled.div`
  flex: 1 1 auto;
  line-height: 15px;
  height: 2.4rem;

  > small {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #48a9b5;
    font-family: "GothamBook";
  }
`

const SmallTextDesc = styled.p`
  font-family: "GothamBook";
  font-size: 12px;
  color: #828282;
  margin-top: 10px;
`
const ImgArrowDown = styled.img`
  /* position: absolute; */
  /* top: 100px; */
  /* margin-top: 150px; */
  /* z-index: 2; */
  /* left: 15vw; */
  /* left: 300px; */
  margin-top: -170px;
  margin-left: -20px;
  @media (max-width: 576px) {
    display: none;
  }
`
