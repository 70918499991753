import React, { useEffect } from "react"
import "../components/layout.css"
import { useLocation } from "@reach/router"
import Footer from "../components/FooterComponents/Footer"
import Seo from "../components/SEOComponents/Seo"

// Import New Component
import HeaderBusiness from "../components/BusinessLanding/HeaderBusiness"
import { BusinessHeader } from "../components/BusinessLanding/BusinessHeader"
import { Features } from "../components/BusinessLanding/Features"
import { Testimonies } from "../components/BusinessLanding/Testimonies"
import { Contact } from "../components/BusinessLanding/Contact"
import { Advantages } from "../components/BusinessLanding/Advantages"
import { BusinessNeeds } from "../components/BusinessLanding/BusinessNeeds"
import { BusinessForm } from "../components/BusinessLanding/Form"

// Import Json
import {
  MainPageJson,
  PathJson,
  BusinessJson,
  BusinessNewJson,
} from "../components/location"
import { navigate } from "gatsby"

function Business() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const BusinessContent = BusinessJson()
  const BusinessContentNew = BusinessNewJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  
  return (
    <div className="maxwidth">
      <Seo title="business" />
      <BusinessHeader
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={BusinessContentNew.Header}
        isEnglish={isEnglish}
      />
      <Advantages props={BusinessContentNew.Advantages} />
      <Features props={BusinessContentNew.Features} />
      <BusinessNeeds props={BusinessContentNew.Reasons} />
      <Testimonies props={BusinessContentNew.Testimonies} />
      <Contact props={BusinessContentNew.Contact} />
      <BusinessForm props={BusinessContentNew.Form} propsLink={PathContent} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default Business
